.App {
}

.odd {
	background: #f9f9f9;
}

.even {
}

#logo {
  width: 6rem; /* Fixed width */
  height: auto; /* Height automatically adjusts to maintain aspect ratio */
}

.header {
	background: ivory;
}

.highcharts-button-symbol {
	width:24px;
	height:24px;
	background-color: red;
}

.highcharts-button-symbol svg * {
	width:  100%;
	height: 100%;
	fill:   blue;
}

.footer {
	margin-top: 0 !important;
}

.dropdown-menu {
	--bs-dropdown-link-active-bg: transparent !black;
	--bs-dropdown-link-hover-bg:lightgray !important;
}

#search_control {
	background-color: rgb(246, 248, 250);
	max-width:30%;
	border-style: solid;
	border-radius: 6px ;
	border-color: darkgray ;
	border-width: 0.5px ;
	margin-top: 6px ;
	margin-right: 4px ;
}

#search_input {
	background-color: transparent;
	border-style: none;
	outline:none;
		&:focus{
			border: none;
			box-shadow: none;
			background-color: transparent;
			outline:none;
		}
}

#search_close{

	max-width : 0.25px;
	margin-top: 5px ;
	margin-right: 8px ;
	margin-left: 8px ;
	border-style: none;
		&:focus{
			border: none;
			box-shadow: none;
			background-color: transparent;
			outline:none;
		}
}

.data-highcharts-chart{
	height: 100%;
}

.loginform {
	text-align: center;
	color: darkgray;
	font-size: 11;
	font-weight: bold;
	align-self: center;
}

.loginfragment{
	text-align: center;
	color: darkgray;
	font-size: 11;
	font-weight: bold;
	margin: 0 auto;
	align-self: center;
	background-color: 'lightgreen';

	width : '60px';
	height : '60px';
}

.loginform form button{
	
	color: darkgray;
	font-size: 14;
	font-weight: bold;
}

.highcharts-xaxis-labels  text {
	font-weight: bold !important;
	font-size: 14px !important;
}

.ag-row {
	--ag-range-selection-border-style : none;
	color : #6c757d !important;
}

.ag-menu-option-text {
	text-align: left;
}

.center {
	margin: 0 auto;
	align-self: center;
}

.Tooltip {
	width: max-content;
	background-color: #444;
	color: white;
	font-size: 10px;
	padding: 4px 8px;
	border-radius: 4px;
}

.navbar{
	background: #003366;
	font-size: 20px !important;
	min-width: 100%;
}

.grid-button {
		background-color: transparent;
		border-color: darkgray;
	border-width : thin !important;
}

.grid-button:hover {
		background-color: ivory;
		border-color: darkgray;
		border-width : none;
		border-radius : 3.5px;
}

.grid-button-icon {
		color: darkgray;
}

.grid-button-icon:hover {
		color: darkgray;
}

#navbar .btn-primary:not(.loginform){
		color: var(--bs-btn-hover-color);
		background-color: var(--bs-btn-hover-bg);
		border-color: yellow;
		--bs-btn-bg:transparent !important;
		--bs-btn-hover-bg:transparent !important;
		--bs-btn-active-bg:transparent !important;
		--bs-btn-hover:yellow !important;
		--bs-btn-hover-border-color:yellow !important;
}

.navbar .dropdown {
	color : ivory;
	font-size : 20px !important;
	background : #003366 !important; 
	border-color : ivory!important;   
}

.navbar-button{
	border : none !important;
	font-size: 20px !important;
	color: ivory !important;
	background: #003366  !important;
	border-color : ivory !important;   
}

.navbar-button .active{
	font-size: 20px !important;
	color: ivory !important;
	background: #003366  !important;
	border-color : ivory !important;   
}

.tooltip .tooltip-inner {
		background-color: yellow;
		color : black;
}

.tooltip .arrow::before {
		border-top-color: yellow;
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.center-cropped {
	height: 150px;
	background-position: center center;
	background-repeat: no-repeat;
}

.center-image{
	height:80px;
	width:400px;
	margin: auto;
	margin-bottom: 4px;

	background:orange;
	position:relative;

	align-items: left;
	justify-content: left;
	color: ivory;

 }

.center-image img{
	max-width:100%;
	max-height:100%;
	padding-top: 14px;

	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

